body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mymodal-backdrop {
  display: block;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.3);
}

.mymodal {
  background-color: #fff;
  margin: 5% auto;
  border-radius: 5px;
  width: 60%;
  padding: 30px;
  position: relative;
  text-align: center;
}

.mymodal-close {
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  float: right;
  color: rgb(0,0,0,0.5);
}

.print-modal-backdrop {
  display: block;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}

.print-instructions {
  border-radius: 15px;
  padding: 20px;
  background-color: #7abcd6;
  border: 1px solid #15759b;
}

i.icon-huge {
  font-size: 100px;
}

td.description {
  white-space: pre-line;
}

.fa-floppy-disk::before {
  content: "\f0c7";
}

.fa-pencil::before {
  content: "\f303";
}

.fa-gear::before {
  content: "\f013";
}

.fa-triangle-exclamation::before {
  content: "\f071";
}

.w-fit {
  width: fit-content;
}

/* Tooltip container */
.tooltip-container {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

/* Tooltip text */
.tooltip-container .tooltip-text, .tooltip-container .tooltip-text-bottom{
  visibility: hidden;
  width: 240px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 999 !important;
  left: 50%;
  margin-left: -120px; /* Use half of the width to center the tooltip */
}

.tooltip-container .tooltip-text {
  bottom: 100%;
}

.tooltip-container .tooltip-text-bottom {
  top: 100%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-container:hover .tooltip-text, .tooltip-container:hover .tooltip-text-bottom {
  visibility: visible;
}

.tooltip-container .tooltip-text::after, .tooltip-container .tooltip-text-bottom::after {
  content: " ";
  position: absolute;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
}

.tooltip-container .tooltip-text::after {
  top: 100%; /* At the bottom of the tooltip */
  border-color: black transparent transparent transparent;
}

.tooltip-container .tooltip-text-bottom::after {
  bottom: 100%; /* At the top of the tooltip */
  border-color: transparent transparent black transparent;
}

.section-outline {
  border: 2px solid #d3d3d3;
  border-radius: 5px;
  padding: 10px;
}

.table-layout-fixed {
  table-layout: fixed;
}

.table-layout-fixed input, .table-layout-fixed select, .table-layout-fixed textarea {
  max-width: 100%
}

.fa-up-right-from-square::before {
  content: "\f35d";
}

.fa-file-lines::before {
  content: "\f15c";
}